import Axios from 'axios';

export interface Dict {
  [key: string]: string;
}

export class PintraRedirect {
  public async toMsOnline(loginHint: string = ''): Promise<void> {
    const data = new FormData();
    data.append('action', 'auth_url');
    if (!!loginHint) {
      data.append('login_hint', loginHint);
    }
    const qs = this.queryString();
    if ('redirect_to' in qs) {
      data.append('redirect_to', qs['redirect_to']);
    }
    try {
      const response: any = await Axios.post((window as any).wpo365.wpAjaxAdminUrl, data);
      const url = JSON.parse(response.data.result).authUrl;
      window.location.href = url;
    } catch (err) {
      console.log(err);
      throw 'PintraRedirect -> Error occured whilst trying to redirect to MS online';
    }
  }

  private queryString = (): Dict => {
    const qs = window.location.href.split('?');
    if (!Array.isArray(qs) || qs.length != 2) {
      return {};
    }
    const kvPairs = qs[1].split('&');
    const result: Dict = {};
    for (let i = 0; i < kvPairs.length; i++) {
      const kv = kvPairs[i].split('=');
      result[kv[0]] = kv.length == 2 ? kv[1] : '';
    }
    return result;
  };
}
